import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import good_doggy from "../images/svg/good_doggy.svg"

const IndexPage = props => (
  <Layout>
    <SEO title="Home" />
    <section className="hero">
      <div className="hero-body">
        <div className="container" style={{ maxWidth: 2800 }}>
          <div className="columns is-vcentered has-text-centered">
            <div className="column">
              <h1 className="title">Personalized Pet Pillows</h1>
              <h2 className="subtitle">
                Guaranteed to make you and your family smile!
              </h2>
              <div className="columns has-text-centered">
                <div className="column is-6">
                  <Link to="/product/personalized-pet-pillows">
                    <button className="button is-primary is-large">
                      THROW PILLOWS
                    </button>
                  </Link>
                </div>
                <div className="column is-6">
                  <Link to="/product/personalized-pet-sequin-pillows">
                    <button className="button is-primary is-large">
                      SEQUIN PILLOWS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="column">
              <figure>
                <Img
                  fluid={props.data.homepage.childImageSharp.fluid}
                  style={{ borderRadius: 10 }}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    homepage: file(relativePath: { eq: "homepage.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
